let datatables_helper = {
    textPaginationStyle: function () {
        $.fn.dataTableExt.oPagination.extStyle = {


            "fnInit": function (oSettings, nPaging, fnCallbackDraw) {

                var oPaging = oSettings.oInstance.fnExtStylePagingInfo();

                var ul = $('<ul class="pagination"></ul>');

                var nFirst = $('<li class="paginate_button first"><a href="#" data-dt-idx="0" tabindex="0"><<</a></li>');
                var nPrevious = $('<li class="paginate_button previous"><a href="#" data-dt-idx="0" tabindex="0"><</a></li>');
                var nNext = $('<li class="paginate_button next"><a href="#" data-dt-idx="0" tabindex="0">></a></li>');
                var nLast = $('<li class="paginate_button last"><a href="#" data-dt-idx="0" tabindex="0">>></a></li>');
                var nPageNumBox = $('<li class="paginate_button"><input type="text" class="paginate_input" value=""><span class="paginate_total"></span></li>');

                ul
                    .append(nFirst)
                    .append(nPrevious)
                    .append(nPageNumBox)
                    .append(nNext)
                    .append(nLast);


                $(nPaging).append(ul);

                nFirst.find('a').click(function (e) {
                    e.preventDefault();

                    if ($(this).parent().hasClass("disabled"))
                        return;

                    // disabilito i tasti paginazione
                    $(nPaging).find(".paginate_button").addClass("disabled");

                    oSettings.oApi._fnPageChange(oSettings, "first");
                    fnCallbackDraw(oSettings);
                }).bind('selectstart', function () {
                    return false;
                });

                nPrevious.find('a').click(function (e) {
                    e.preventDefault();

                    if ($(this).parent().hasClass("disabled"))
                        return;

                    // disabilito i tasti paginazione
                    $(nPaging).find(".paginate_button").addClass("disabled");

                    oSettings.oApi._fnPageChange(oSettings, "previous");
                    fnCallbackDraw(oSettings);
                }).bind('selectstart', function () {
                    return false;
                });

                nNext.find('a').click(function (e) {
                    e.preventDefault();

                    if ($(this).parent().hasClass("disabled"))
                        return;

                    // disabilito i tasti paginazione
                    $(nPaging).find(".paginate_button").addClass("disabled");

                    oSettings.oApi._fnPageChange(oSettings, "next");
                    fnCallbackDraw(oSettings);
                }).bind('selectstart', function () {
                    return false;
                });

                nLast.find('a').click(function (e) {
                    e.preventDefault();
                    if ($(this).parent().hasClass("disabled"))
                        return;

                    // disabilito i tasti paginazione
                    $(nPaging).find(".paginate_button").addClass("disabled");

                    oSettings.oApi._fnPageChange(oSettings, "last");
                    fnCallbackDraw(oSettings);
                }).bind('selectstart', function () {
                    return false;
                });

                nPageNumBox.find('.paginate_input').change(function () {
                    var pageValue = parseInt($(this).val(), 10) - 1;
                    var oPaging = oSettings.oInstance.fnExtStylePagingInfo();

                    if (pageValue < 0) {
                        pageValue = 0;
                    } else if (pageValue >= oPaging.iTotalPages) {
                        pageValue = oPaging.iTotalPages - 1;
                    } else if (isNaN(pageValue)) {
                        pageValue = oPaging.iPage;
                    }

                    // disabilito i tasti paginazione
                    $(nPaging).find(".paginate_button").addClass("disabled");

                    oSettings.oApi._fnPageChange(oSettings, pageValue);
                    fnCallbackDraw(oSettings);
                });

            },


            "fnUpdate": function (oSettings, fnCallbackDraw) {
                if (!oSettings.aanFeatures.p) {
                    return;
                }

                var prev, next;

                var oPaging = oSettings.oInstance.fnExtStylePagingInfo();

                /* Loop over each instance of the pager */
                var an = oSettings.aanFeatures.p;

                $(an).find('span.paginate_total').html("/ " + oPaging.iTotalPages);

                var $input = $(an).find('.paginate_input');
                $input.val(oPaging.iPage + 1);

                $(an).each(function (index, item) {

                    var $item = $(item);

                    if (oPaging.iPage == 0) {
                        prev = $item.find('.paginate_button.first').add($item.find('.paginate_button.previous'));
                        prev.addClass("disabled");
                    } else {
                        prev = $item.find('.paginate_button.first').add($item.find('.paginate_button.previous'));
                        prev.removeClass("disabled");
                    }

                    if (oPaging.iPage + 1 == oPaging.iTotalPages) {
                        next = $item.find('.paginate_button.last').add($item.find('.paginate_button.next'));
                        next.addClass("disabled");
                    } else {
                        next = $item.find('.paginate_button.last').add($item.find('.paginate_button.next'));
                        next.removeClass("disabled");
                    }
                });
            }
        };
    },

    selectPaginationStyle: function () {
        $.fn.dataTableExt.oPagination.extStyle = {


            "fnInit": function (oSettings, nPaging, fnCallbackDraw) {

                var oPaging = oSettings.oInstance.fnExtStylePagingInfo();

                var ul = $('<ul class="pagination"></ul>');

                var nFirst = $('<li class="paginate_button first"><a href="#" data-dt-idx="0" tabindex="0"><<</a></li>');
                var nPrevious = $('<li class="paginate_button previous"><a href="#" data-dt-idx="0" tabindex="0"><</a></li>');
                var nNext = $('<li class="paginate_button next"><a href="#" data-dt-idx="0" tabindex="0">></a></li>');
                var nLast = $('<li class="paginate_button last"><a href="#" data-dt-idx="0" tabindex="0">>></a></li>');
                var nPageNumBox = $('<li class="paginate_button"><select class="paginate_select select2"><option value="">&nbsp;</option></select><span class="paginate_total"></span></li>');

                ul
                    .append(nFirst)
                    .append(nPrevious)
                    .append(nPageNumBox)
                    .append(nNext)
                    .append(nLast);


                $(nPaging).append(ul);

                nFirst.find('a').click(function (e) {
                    e.preventDefault();

                    if ($(this).parent().hasClass("disabled"))
                        return;

                    // disabilito i tasti paginazione
                    $(nPaging).find(".paginate_button").addClass("disabled");

                    oSettings.oApi._fnPageChange(oSettings, "first");
                    fnCallbackDraw(oSettings);
                }).bind('selectstart', function () {
                    return false;
                });

                nPrevious.find('a').click(function (e) {
                    e.preventDefault();

                    if ($(this).parent().hasClass("disabled"))
                        return;

                    // disabilito i tasti paginazione
                    $(nPaging).find(".paginate_button").addClass("disabled");

                    oSettings.oApi._fnPageChange(oSettings, "previous");
                    fnCallbackDraw(oSettings);
                }).bind('selectstart', function () {
                    return false;
                });

                nNext.find('a').click(function (e) {
                    e.preventDefault();

                    if ($(this).parent().hasClass("disabled"))
                        return;

                    // disabilito i tasti paginazione
                    $(nPaging).find(".paginate_button").addClass("disabled");

                    oSettings.oApi._fnPageChange(oSettings, "next");
                    fnCallbackDraw(oSettings);
                }).bind('selectstart', function () {
                    return false;
                });

                nLast.find('a').click(function (e) {
                    e.preventDefault();
                    if ($(this).parent().hasClass("disabled"))
                        return;

                    // disabilito i tasti paginazione
                    $(nPaging).find(".paginate_button").addClass("disabled");

                    oSettings.oApi._fnPageChange(oSettings, "last");
                    fnCallbackDraw(oSettings);
                }).bind('selectstart', function () {
                    return false;
                });

                nPageNumBox.find('select').change(function () {
                    var pageValue = parseInt($(this).val(), 10); // -1 because pages are 0 indexed, but the UI is 1
                    var oPaging = oSettings.oInstance.fnExtStylePagingInfo();

                    if (pageValue < 0) {
                        pageValue = 0;
                    } else if (pageValue >= oPaging.iTotalPages) {
                        pageValue = oPaging.iTotalPages - 1;
                    }

                    // disabilito i tasti paginazione
                    $(nPaging).find(".paginate_button").addClass("disabled");

                    oSettings.oApi._fnPageChange(oSettings, pageValue);
                    fnCallbackDraw(oSettings);
                });

            },


            "fnUpdate": function (oSettings, fnCallbackDraw) {
                if (!oSettings.aanFeatures.p) {
                    return;
                }

                var prev, next;

                var oPaging = oSettings.oInstance.fnExtStylePagingInfo();

                /* Loop over each instance of the pager */
                var an = oSettings.aanFeatures.p;

                $(an).find('span.paginate_total').html("/ " + oPaging.iTotalPages);

                var $select = $(an).find('select.paginate_select');
                $select.html("");
                for (var i = 0; i < oPaging.iTotalPages; i++) { //add the pages
                    var selected = oPaging.iPage == i ? "selected" : "";
                    $select.append('<option value="' + i + '" ' + selected + '>' + (i + 1) + '</option>');
                }

                $(an).each(function (index, item) {

                    var $item = $(item);

                    if (oPaging.iPage == 0) {
                        prev = $item.find('.paginate_button.first').add($item.find('.paginate_button.previous'));
                        prev.addClass("disabled");
                    } else {
                        prev = $item.find('.paginate_button.first').add($item.find('.paginate_button.previous'));
                        prev.removeClass("disabled");
                    }

                    if (oPaging.iPage + 1 == oPaging.iTotalPages) {
                        next = $item.find('.paginate_button.last').add($item.find('.paginate_button.next'));
                        next.addClass("disabled");
                    } else {
                        next = $item.find('.paginate_button.last').add($item.find('.paginate_button.next'));
                        next.removeClass("disabled");
                    }
                });
            }
        };
    }
};